import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

import Amplify from 'aws-amplify'
import config from '../aws-exports'
Amplify.configure(config)

const IndexPage = () => (
  <img
    style={{
      margin: '0 auto',
      width: '100%',
      height: '100%',
      padding: 0,
    }}
    src="https://images.unsplash.com/photo-1588384474880-01be56a6e225?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
  />
)

export default IndexPage
