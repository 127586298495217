/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:fe0d6205-38fd-4fbd-b7c0-2e2c91501dc0",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_5JaEhZAV6",
    "aws_user_pools_web_client_id": "1c3qkvcfc54r95ps0nf7pkg1nt",
    "oauth": {}
};


export default awsmobile;
